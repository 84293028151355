import anime from "animejs"
import { searchAndInitialize, clamp, preventDefault } from "../Utils"
import * as Inputs from "../Inputs"
import DomElement from "../DomElement"

const CLASS_HEADER = ".progress-full__bar"
const CLASS_SECTIONS = ".progress-full__sections > span"
const CLASS_SECTION_ACTIVE = "section--active"

const CLASS_INDICATOR = "indicator"
const CLASS_INDICATOR_CURRENT = "indicator--current"
const CLASS_INDICATOR_COMPLETED = "indicator--completed"

/**
 * Full progress bar component
 */
class ProgressFull extends DomElement {
  private _buttonClickHandler!: (e: Event) => void
  private _keydownHandler!: (e: Event) => void

  private _headerElement!: DomElement<Element>
  private _pages!: NodeListOf<Element>

  private _minValue!: number
  private _value!: number
  private _total!: number

  /**
   * Creates and initializes the ProgressFull component.
   * @param {DomElement} - The root element of the ProgressFull component.
   */
  constructor(element: Element) {
    super(element)
    this._initialize()
  }

  /**
   * Initializes the loader bar component.
   * @private
   */
  protected _initialize() {
    this._buttonClickHandler = this._handleButtonClick.bind(this)
    this._keydownHandler = this._handleKeydown.bind(this)

    this._headerElement = this.find(CLASS_HEADER)!

    this._pages = this.element.querySelectorAll(CLASS_SECTIONS)

    this._minValue = 1
    this._value = 1
    this._total = this._pages.length

    for (let index = 0; index < this._pages.length; index++) {
      if (this._pages[index].classList.contains(CLASS_SECTION_ACTIVE)) {
        this._value = index + 1
      }
    }

    this._addIncicators()
    this._update(-1, this._value, false)

    // Apply the tab index
    const tabIndex = this.getAttribute("tabindex")
    if (tabIndex) {
      this.setAttribute("tabindex", "")
      this._headerElement.setAttribute("tabindex", tabIndex)
    }

    this._headerElement.element.addEventListener("click", this._buttonClickHandler)
    this._headerElement.element.addEventListener("keydown", this._keydownHandler)
  }

  protected _addIncicators() {
    for (let i = this._pages.length - 1; i >= 0; i--) {
      let indicatorElement = new DomElement("button")
        .addClass(CLASS_INDICATOR)
        .setAttribute("data-value", `${i + 1}`)
        .setHtml((i + 1).toString())

      this._headerElement.prependChild(indicatorElement)
    }
  }

  protected _update(oldValue: number, newValue: number, animate = true) {
    let indicators = this._headerElement.element.childNodes

    for (let index = 0; index < indicators.length; index++) {
      let indicatorElement = new DomElement(indicators[index] as Element)

      if (index + 1 < this._value) {
        indicatorElement
          .removeClass(CLASS_INDICATOR_CURRENT)
          .addClass(CLASS_INDICATOR_COMPLETED)
      }

      if (index + 1 === this._value) {
        indicatorElement
          .removeClass(CLASS_INDICATOR_COMPLETED)
          .addClass(CLASS_INDICATOR_CURRENT)
      }

      if (index + 1 > this._value) {
        indicatorElement
          .removeClass(CLASS_INDICATOR_COMPLETED)
          .removeClass(CLASS_INDICATOR_CURRENT)
      }
    }

    if (oldValue !== newValue) {
      let direction = Math.sign(oldValue - newValue)

      if (oldValue > 0 && oldValue !== newValue) {
        let oldSection = new DomElement(this._pages[oldValue - 1])

        if (animate) {
          anime({
            targets: oldSection.element,
            duration: 300,
            left: 100 * direction,
            opacity: 0,
            easing: "easeInOutQuint",
            complete: () => {
              oldSection.removeClass(CLASS_SECTION_ACTIVE)
              oldSection.setAttribute("style", "")
            }
          })
        } else {
          oldSection.removeClass(CLASS_SECTION_ACTIVE)
          oldSection.setAttribute("style", "")
        }
      }

      let newSection = new DomElement(this._pages[newValue - 1])

      if (animate) {
        const el = newSection.element as HTMLElement
        el.style.left = `${-100 * direction}px`
        el.style.opacity = "0"
        newSection.addClass(CLASS_SECTION_ACTIVE)
        anime({
          targets: newSection.element,
          duration: 300,
          left: 0,
          opacity: 1,
          easing: "easeInOutQuint",
          complete: () => {
            newSection.setAttribute("style", "")
          }
        })
      } else {
        newSection.addClass(CLASS_SECTION_ACTIVE)
        newSection.setAttribute("style", "")
      }
    }
  }

  protected _handleButtonClick(event: Event) {
    let element = new DomElement(event.target as Element)
    if (!element.hasClass(CLASS_INDICATOR)) {
      return
    }

    let value = element.getAttribute("data-value")
    this.value = parseFloat(value!)
  }

  protected _handleKeydown(event: Event) {
    const keyboardEvent = event as KeyboardEvent
    let keycode = keyboardEvent.which || keyboardEvent.keyCode

    if (keycode === Inputs.KEY_ARROW_RIGHT) {
      this.value++

      preventDefault(keyboardEvent)
      return
    }

    if (keycode === Inputs.KEY_ARROW_LEFT) {
      this.value--

      preventDefault(keyboardEvent)
      return
    }

    if (keycode >= Inputs.KEY_NR_0 && keycode <= Inputs.KEY_NR_9) {
      this.value = keycode - Inputs.KEY_NR_0
      preventDefault(keyboardEvent)
      return
    }
  }

  /**
   * Gets the current progress value in the range of 1..total.
   */
  get value() {
    return this._value
  }

  /**
   * Sets the current progress.
   * @param {number} - The progress in the range of 1..total.
   */
  set value(val: number) {
    const oldValue = this._value

    this._value = clamp(val, this._minValue, this._total)
    this._update(oldValue, this._value, true)

    this.dispatchEvent("changed")
  }

  /**
   * Gets the total progress value.
   */
  get total() {
    return this._total
  }
}

export function init() {
  searchAndInitialize(".progress-full", (e) => {
    new ProgressFull(e)
  })
}

export default ProgressFull
