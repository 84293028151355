import { searchAndInitialize } from "../Utils"
import DomElement from "../DomElement"
import * as Dom from "../DomFunctions"

const QUERY_TEXTAREA = "textarea"
const CLASS_HAS_VALUE = "is-fixed"

/**
 * Textarea component
 */
class Textarea extends DomElement {
  private _area: HTMLTextAreaElement

  private _focusChangedHandler: (e: Event) => void
  private _valueChangedHandler: (e: Event) => void
  private _resizeHandler: (e: Event) => void

  private _minRows!: number
  private _maxRows!: number
  private _lineHeight!: number

  private _updateBaseHeight!: boolean

  private _baseHeight!: number
  private _baseScrollHeight!: number

  constructor(element: Element) {
    super(element)

    this._area = this.element.querySelector(QUERY_TEXTAREA)!

    this._focusChangedHandler = this._focusChanged.bind(this)
    this._valueChangedHandler = this._onValueChanged.bind(this)
    this._resizeHandler = this._updateHeight.bind(this)

    this._initialize()
  }

  /**
   * Initializes the textarea component.
   * @private
   */
  protected _initialize() {
    this._minRows = parseInt(this._area.getAttribute("data-min-rows") || "3", 10)
    this._maxRows = parseInt(this._area.getAttribute("data-max-rows")!, 10) || Number.MAX_SAFE_INTEGER

    // Make sure min an max are property specified
    this._minRows = Math.min(this._minRows, this._maxRows)
    this._maxRows = Math.max(this._minRows, this._maxRows)

    this._lineHeight = parseInt(Dom.css(this._area, "line-height"), 10)

    this._updateBaseHeight = Dom.isHidden(this._area, true)
    this._calculateBaseHeight()

    // add event listeners
    this._area.addEventListener("focus", this._focusChangedHandler)
    this._area.addEventListener("blur", this._focusChangedHandler)
    this._area.addEventListener("input", this._valueChangedHandler)

    window.addEventListener("resize", this._resizeHandler)
    window.addEventListener("orientationchange", this._resizeHandler)

    this._onValueChanged()
  }

  protected _calculateBaseHeight() {
    // temporary clear the content to take measurements
    let value = this._area.value
    this._area.value = ""

    this._baseHeight = this._area.offsetHeight - this._lineHeight
    this._baseScrollHeight = this._area.scrollHeight - this._lineHeight

    // restore initial content
    this._area.value = value
  }

  protected _focusChanged() {
    this._updateHeight()
  }

  protected _updateHeight() {
    let hasFocus = this._area === document.activeElement
    let maxRows, rows = 0

    if (this._updateBaseHeight === true && Dom.isHidden(this._area, true) === false) {
      this._calculateBaseHeight()
      this._updateBaseHeight = false
    }

    // Calculate the apropriate size for the control
    if (!this._hasValue()) {
      // Handle empty states
      rows = hasFocus === true ? this._minRows : 1
      maxRows = rows
    } else {
      // Reset the height for calculation of the row count
      this._area.style.height = "auto"

      // Get the new height
      rows = Math.ceil((this._area.scrollHeight - this._baseScrollHeight) / this._lineHeight) + 1
      maxRows = Math.max(Math.min(this._maxRows, rows), this._minRows)
    }

    if (rows > this._maxRows) {
      this._area.style.overflow = "auto"
    } else {
      this._area.style.overflow = "hidden"
    }

    const height = ((maxRows - 1) * this._lineHeight) + this._baseHeight
    this._area.style.height = `${height}px`
  }

  protected _hasValue() {
    return this._area.value && this._area.value.length > 0
  }

  protected _onValueChanged() {
    if (this._hasValue()) {
      Dom.addClass(this._area, CLASS_HAS_VALUE)
    } else {
      Dom.removeClass(this._area, CLASS_HAS_VALUE)
      this._area.value = ""
    }

    this._updateHeight()
  }

  /**
   * Destroys the component and clears all references.
   */
  public destroy() {
    window.removeEventListener("resize", this._resizeHandler)
    window.removeEventListener("orientationchange", this._resizeHandler)

    this._area.removeEventListener("focus", this._focusChangedHandler)
    this._area.removeEventListener("blur", this._focusChangedHandler)
    this._area.removeEventListener("input", this._valueChangedHandler);

    (this as any)._focusChangedHandler = null;
    (this as any)._valueChangedHander = null;
    (this as any)._area = null;
    (this as any)._minRows = null;
    (this as any)._maxRows = null;
    (this as any)._lineHeight = null;
    (this as any)._baseHeight = null;
    (this as any)._baseScrollHeight = null;
    (this as any).element = null
  }
}

export function init() {
  searchAndInitialize(".input-multiline, .input-field--multiline", (e) => {
    new Textarea(e)
  })
}

export default Textarea
