import { searchAndInitialize, preventDefault } from "../Utils"
import anime from "animejs"
import { hasClass, addClass, removeClass } from "../DomFunctions"
import DomElement from "../DomElement"

const CLASS_OPEN = "is-open"
const CLASS_ACTIVE = "is-active"

const QUERY_SITE_WRAPPER = ".js-site-wrapper"
const QUERY_NAV_HAMBURGER = ".js-site-wrapper .js-hamburger"

const QUERY_NAV_ITEM = ".js-nav-item"

const NAV_LINK_INITIAL_SCALE = 0.9
const ANIMATION_DURATION_LINKS = 100
const ANIMATION_DURATION_NAV = 300

const ANIMATION_STAGGER_DELAY = 50

/**
 * The navigation side component definition.
 */
class NavigationSide extends DomElement {
  private _clickHandler: (event: Event) => void
  private _windowClickHandler: (event: Event) => void

  private _siteWrapper: Element
  private _hamburgerElement: Element
  private _navItems: NodeListOf<Element>

  constructor(element: Element) {
    super(element)

    this._clickHandler = this._handleClick.bind(this)
    this._windowClickHandler = this._handleWindowClick.bind(this)

    this._siteWrapper = document.querySelector(QUERY_SITE_WRAPPER)!
    this._hamburgerElement = document.querySelector(QUERY_NAV_HAMBURGER) || document.createElement("div")
    this._navItems = this.element.querySelectorAll(QUERY_NAV_ITEM)!

    this._initialize()
  }

  protected _initialize() {
    this._hamburgerElement.addEventListener("click", this._clickHandler)
    this._hamburgerElement.addEventListener("touchend", this._clickHandler)
  }

  protected _handleClick(event: Event) {
    preventDefault(event)
    this.toggle()
  }

  protected _handleWindowClick(event: Event) {
    let target = event.target as Element

    while (target !== this.element && target.parentElement) {
      target = target.parentElement
    }

    if (target !== this.element) {
      this.close()
      return false
    }

    return true
  }

  /**
   * Toggles the side navigation.
   */
  public toggle() {
    if (hasClass(this.element, CLASS_OPEN) === false) {
      this.open()
    } else {
      this.close()
    }
  }

  /**
   * Opens the slide navigation.
   */
  public open() {
    setTimeout(() => {
      window.addEventListener("click", this._windowClickHandler)
      window.addEventListener("touchend", this._windowClickHandler)
    }, 50)

    addClass(this._hamburgerElement, CLASS_ACTIVE)
    addClass(this.element, CLASS_OPEN)
    addClass(this._siteWrapper, CLASS_OPEN)

    const x = anime.timeline()
    let off = ANIMATION_DURATION_NAV
    this._navItems.forEach((element) => {
      const el = element as HTMLElement
      el.style.opacity = "0"
      el.style.transform = `scale(${NAV_LINK_INITIAL_SCALE})`
      x.add({
        targets: el,
        duration: ANIMATION_DURATION_LINKS,
        opacity: 1,
        scale: 1,
        easing: "linear",
        offset: off
      })
      off += ANIMATION_STAGGER_DELAY
    })
  }

  /**
   * Closes the side navigation.
   */
  public close() {
    window.removeEventListener("click", this._windowClickHandler)
    window.removeEventListener("touchend", this._windowClickHandler)

    removeClass(this._hamburgerElement, CLASS_ACTIVE)
    removeClass(this.element, CLASS_OPEN)
    removeClass(this._siteWrapper, CLASS_OPEN)
  }

  /**
   * Destroys the component and removes all event
   * subscriptions and references.
   */
  public destroy() {
    window.removeEventListener("click", this._windowClickHandler)
    window.removeEventListener("touchend", this._windowClickHandler);

    (this as any)._windowClickHandler = null;

    (this as any)._clickHandler = null;
    (this as any)._siteWrapper = null;

    (this as any)._hamburgerElement = null;
    (this as any)._navItems = null
  }
}

export function init() {
  searchAndInitialize(".nav-side", (e) => {
    new NavigationSide(e)
  })
}

export default NavigationSide
