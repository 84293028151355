import anime from "animejs"

import { searchAndInitialize, clamp, preventDefault } from "../Utils"
import * as Inputs from "../Inputs"
import DomElement from "../DomElement"

const MARGIN_TICK = 32
const CLASS_HTML5 = "html5"
const RANGE_LIGHT = "range--light"

const CLASS_CONTAINER = "range-container"
const CLASS_SLIDER = "range-slider"
const CLASS_ACTIVE = "range--active"

const CLASS_TRACK = "range-track"
const CLASS_TRACK_PROGRESS = "range-track__progress"

const CLASS_TICK = "range-tick"
const CLASS_TICK_LABEL = "range-tick__label"
const CLASS_TICK_ACTIVE = "range-tick--active"

const CLASS_THUMB = "range-thumb"
const CLASS_THUMB_VALUE = "range-thumb__value"
const CLASS_DISABLED = "range--disabled"

const CLASS_DRAGGING = "range--dragging"

export interface Formatter {
  (value: number, short: boolean): string
}

export interface Option {
  value: number
  label: string | number
}

/**
 * The range slider component definition.
 */
class Range extends DomElement<HTMLInputElement> {
  private _downHandler: (e: MouseEvent | TouchEvent) => void
  private _moveHandler: (e: MouseEvent | TouchEvent) => void
  private _endHandler: (e: MouseEvent | TouchEvent) => void
  private _keydownHandler: (e: KeyboardEvent) => void
  private _focusHandler: (e: Event) => void
  private _blurHandler: (e: Event) => void
  private _resizeHandler: (e: Event) => void

  private _wrapperElement!: DomElement<HTMLElement>
  private _rangeContainer!: DomElement<HTMLDivElement>
  private _rangeTrack!: DomElement<HTMLDivElement>
  private _rangeProgress!: DomElement<HTMLDivElement>
  private _ticksWrapper!: DomElement<HTMLDivElement>
  private _rangeThumb!: DomElement<HTMLDivElement>
  private _thumbValue!: DomElement<HTMLDivElement>
  private _outputLabel?: DomElement<Element>

  private _minValue!: number
  private _maxValue!: number
  private _trackValueTotal!: number

  private _grabPosition!: number
  private _trackWidth!: number
  private _trackPositionMin!: number
  private _trackPositionMax!: number
  private _trackLeftPosition!: number
  private _itemWidth!: number

  private _formatter!: Formatter

  constructor(element: HTMLInputElement) {
    super(element)

    // Setup event context
    this._downHandler = this._handleDown.bind(this)
    this._moveHandler = this._handleMove.bind(this)
    this._endHandler = this._handleEnd.bind(this)
    this._keydownHandler = this._handleKeydown.bind(this)

    this._focusHandler = this._handleFocus.bind(this)
    this._blurHandler = this._handleBlur.bind(this)
    this._resizeHandler = this.layout.bind(this)

    this._initialize()

    if (this.element.disabled) {
      this.disable()
    } else {
      this.enable()
    }
  }

  /**
   * Initializes the range slider component.
   *
   * This method inspects the select definition and its options and
   * generates new stylable DOM elements around the original range input-element
   * definitions.
   * @private
   */
  protected _initialize() {

    if (this.hasClass(CLASS_HTML5)) {
      // This element uses HTML5 styling, do not touch it...
      return
    }

    this._wrapperElement = new DomElement(this.element.parentElement!)

    this._rangeContainer = new DomElement<HTMLDivElement>("div")
      .addClass(CLASS_CONTAINER)

    this._rangeTrack = new DomElement<HTMLDivElement>("div")
      .addClass(CLASS_TRACK)

    // check if range--light slider then add progress
    if (this._wrapperElement.hasClass(RANGE_LIGHT)) {
      this._rangeProgress = new DomElement<HTMLDivElement>("div")
        .addClass(CLASS_TRACK_PROGRESS)

      this._rangeTrack.appendChild(this._rangeProgress)
    }

    this._rangeThumb = new DomElement<HTMLDivElement>("div")
      .addClass(CLASS_THUMB)

    this._ticksWrapper = new DomElement<HTMLDivElement>("div")
      .addClass(CLASS_SLIDER)

    this._rangeContainer.appendChild(this._rangeTrack)
    this._rangeContainer.appendChild(this._ticksWrapper)
    this._rangeContainer.appendChild(this._rangeThumb)

    // add container to wrapper
    this._wrapperElement.appendChild(this._rangeContainer)

    // get min & max definitions
    this._minValue = parseFloat(this.element.min) || 0
    this._maxValue = parseFloat(this.element.max) || 1

    // get the label/output format string
    this._formatter = (window as any)[this.getAttribute("formatter")!]

    // get the output label and move it below the container
    if (this.element.id) {
      this._outputLabel = this._wrapperElement.find(`output[for='${this.element.id}']`)
      if (this._outputLabel) {
        this._wrapperElement.appendChild(this._outputLabel)
      }
    }

    if (!this.element.step) {
      // fix issues with float sliders if the step is undefined
      this.element.step = "any"
    }

    const options = this._getOptionsList()
    if (options && options.length) {
      this._addTicks(options)
    }

    if (this._rangeContainer.element.querySelectorAll(`.${CLASS_TICK_LABEL}`).length <= 1) {
      this._thumbValue = new DomElement<HTMLDivElement>("div")
        .addClass(CLASS_THUMB_VALUE)

      this._rangeThumb.appendChild(this._thumbValue)
    }

    this._trackValueTotal = this._maxValue - this._minValue
    this.layout()

    this._updateTickState()

    // Apply the tab index
    const tabIndex = this.element.getAttribute("tabindex")
    if (tabIndex) {
      this._rangeContainer.setAttribute("tabindex", tabIndex)
    }

    window.addEventListener("resize", this._resizeHandler)
    window.addEventListener("orientationchange", this._resizeHandler)
  }

  protected _getOptionsList() {
    let options: Option[] = []

    let listId = this.getAttribute("list")
    if (listId) {
      let dataList = document.querySelector(`#${listId}`)
      if (dataList) {
        for (let entry of dataList.querySelectorAll("option")) {
          let value = parseFloat(entry.innerText)
          let label = entry.getAttribute("label") || parseFloat(value.toFixed(2))

          options.push({
            value,
            label
          })
        }
      }
    }

    // Sort the list to enable snapping
    options = options.sort((a, b) => a.value - b.value)

    if (options.length > 1) {
      this._minValue = Number.MAX_VALUE
      this._maxValue = Number.MIN_VALUE

      for (let i = 0; i < options.length; i++) {
        this._minValue = Math.min(this._minValue, options[i].value)
        this._maxValue = Math.max(this._maxValue, options[i].value)
      }
    }

    return options
  }

  protected _addTicks(dataItems: Option[]) {
    for (let entry of dataItems) {
      let tickElement = new DomElement("div")
        .setAttribute("data-value", String(entry.value))
        .addClass(CLASS_TICK)

      let tickLabel = new DomElement("span")
        .addClass(CLASS_TICK_LABEL)
        .setHtml(String(entry.label))

      tickElement.appendChild(tickLabel)
      this._ticksWrapper.appendChild(tickElement)
    }
  }

  protected _isEventOnLabel(event: Event) {
    return (event.target as Element).classList.contains(CLASS_TICK_LABEL)
  }

  protected _handleDown(event: MouseEvent | TouchEvent) {
    this._wrapperElement.addClass(CLASS_DRAGGING)

    this._rangeContainer.element.addEventListener("mouseup", this._endHandler)
    document.addEventListener("mousemove", this._moveHandler)
    document.addEventListener("mouseup", this._endHandler)

    this._rangeContainer.element.addEventListener("touchmove", this._moveHandler)
    document.addEventListener("touchend", this._endHandler)

    // Ignore clicks directly on the thumb
    if (event.target !== this._rangeThumb.element && !this._isEventOnLabel(event)) {
      let pos = this._getRelativePosition(event)
      this._setPosition(pos, true, false, false)
    }
  }

  protected _handleMove(event: MouseEvent | TouchEvent) {
    preventDefault(event)
    this._unfocus()

    if (!this._isEventOnLabel(event)) {
      let pos = this._getRelativePosition(event)
      this._setPosition(pos, true, false, false)
    }
  }

  protected _handleEnd(event: MouseEvent | TouchEvent) {
    this._wrapperElement.removeClass(CLASS_DRAGGING)

    this._rangeContainer.element.removeEventListener("mouseup", this._endHandler)
    document.removeEventListener("mouseup", this._endHandler)
    document.removeEventListener("mousemove", this._moveHandler)

    this._rangeContainer.element.removeEventListener("touchmove", this._moveHandler)
    document.removeEventListener("touchend", this._endHandler)

    let pos = this._getRelativePosition(event)
    this._setPosition(pos, true, true, true)
    this._handleBlur()
  }

  protected _handleKeydown(event: KeyboardEvent) {
    let keycode = event.which || event.keyCode

    if (keycode === Inputs.KEY_ESCAPE) {
      // handle Escape key (ESC)
      this._rangeContainer.element.blur()
      return
    }

    const isUp = keycode === Inputs.KEY_ARROW_UP || keycode === Inputs.KEY_ARROW_RIGHT
      || keycode === Inputs.KEY_PAGE_UP

    const isDown = keycode === Inputs.KEY_ARROW_DOWN || keycode === Inputs.KEY_ARROW_LEFT
      || keycode === Inputs.KEY_PAGE_DOWN

    if (isUp || isDown) {
      event.preventDefault()

      let direction = isDown ? -1 : 1

      // make a larger step if its the vertical arrow or page keys
      if (keycode === Inputs.KEY_ARROW_UP || keycode === Inputs.KEY_ARROW_DOWN ||
        keycode === Inputs.KEY_PAGE_UP || keycode === Inputs.KEY_PAGE_DOWN) {
        direction *= 10
      }

      let val = this.value
      if (this._ticksWrapper.element.childNodes.length > 1) {
        val = this._getNextValue(val, direction)
      } else {

        let step: string | number = this.element.step
        if (!step || step === "any") {
          step = "0.1"
        }
        let newVal = val + (parseFloat(step) * direction)
        val = newVal
      }

      this._setValue(val, true, true)
      return
    }
  }

  protected _handleFocus() {
    this._rangeContainer.addClass(CLASS_ACTIVE)
  }

  protected _handleBlur() {
    this._rangeContainer.removeClass(CLASS_ACTIVE)
  }

  protected _unfocus() {
    if ((document as any).selection) {
      (document as any).selection.empty()
    } else {
      window.getSelection()!.removeAllRanges()
    }
  }

  protected _getRelativePosition(event: MouseEvent | TouchEvent) {
    let pageX
    if ("pageX" in event) {
      pageX = event.pageX
    } else {
      pageX = (event.touches[0] || event.changedTouches[0]).pageX
    }

    return pageX - this._trackLeftPosition + this._grabPosition
  }

  /**
   * Validates and updates the position and sets the corresponding value on the slider.
   * @param {position} the new position to set.
   * @param {updateValue} true if the value should be updated as well; otherwise false.
   * @param {snap} true if snapping should be used; otherwise false.
   * @param {animate} true if the UI update should be animated; otherwise false.
   * @private
   */
  protected _setPosition(
    position: number,
    updateValue = true,
    snap = false,
    animate = true
  ) {
    if (position === undefined || position === null || Number.isNaN(position)) {
      throw new Error("Position is not a number")
    }

    // Clamp to min and max range
    let newPos = clamp(position, this._trackPositionMin, this._trackPositionMax)
    if (updateValue) {
      let value = (this._trackValueTotal / this._trackWidth) * newPos + this._minValue

      if (this._ticksWrapper.element.childNodes.length > 1 && snap) {
        let snapPos = this._getSnapPosition(newPos)
        newPos = snapPos.position
        value = snapPos.value
      } else if (this.element.step && this.element.step !== "any") {
        const step = parseFloat(this.element.step)
        value = Math.round(value / step) * step
      }

      this._setValue(value, false, false)
    }

    if (animate && updateValue) {
      this._updateTickState()
    }

    if (animate) {
      anime({
        targets: this._rangeThumb.element,
        duration: 200,
        left: newPos,
        easing: "easeInOutQuint"
      })

      if (this._rangeProgress) {
        anime({
          targets: this._rangeProgress.element,
          duration: 200,
          width: newPos,
          easing: "easeInOutQuint"
        })
      }
    } else {
      this._rangeThumb.element.style.left = newPos + "px"

      if (this._rangeProgress) {
        this._rangeProgress.element.style.width = newPos + "px"
      }
    }
  }

  /**
   * Gets the snap value corresponding to the given value.
   * @param {value} the target value.
   * @returns an object containing the snap position and the corresponding value.
   * @private
   */
  protected _getSnapValue(value: number) {
    const ticks = this._ticksWrapper.element.children
    let currentPosition = 0

    for (let i = 0; i < ticks.length; i++) {

      let currentElement = new DomElement(ticks[i])
      let currentValue = parseFloat(currentElement.getAttribute("data-value")!)
      let currentWidth = currentElement.element.clientWidth

      let nextElement
      let nextValue = Number.MAX_VALUE

      if (i < ticks.length - 1) {
        nextElement = new DomElement(ticks[i + 1])
        nextValue = parseFloat(nextElement.getAttribute("data-value")!)
      }

      // left most element
      if (i === 0 && value <= currentValue) {
        return {
          value: currentValue,
          position: MARGIN_TICK - this._grabPosition
        }
      }

      // right most element
      if (!nextElement && value >= currentValue) {
        return {
          value: currentValue,
          position: currentPosition + (currentWidth - MARGIN_TICK) - this._grabPosition - 1
        }
      }

      if (value >= currentValue && value < nextValue) {
        return {
          value: currentValue,
          position: currentPosition + (0.5 * currentWidth) - this._grabPosition
        }
      }

      currentPosition += currentWidth
    }

    throw new Error("Could not determine snap value")
  }

  /**
   * Gets the snap position corresponding to the given position.
   * @param {position} the target position.
   * @returns an object containing the snap position and the corresponding value.
   * @private
   */
  protected _getSnapPosition(position?: number | null) {
    if (position === undefined || position === null || Number.isNaN(position)) {
      throw new Error("position is not a number")
    }

    const ticks = this._ticksWrapper.element.children
    let currentPosition = 0

    for (let i = 0; i < ticks.length; i++) {

      let currentElement = new DomElement(ticks[i])
      let currentValue = parseFloat(currentElement.getAttribute("data-value")!)
      let currentWidth = currentElement.element.clientWidth

      let nextElement

      if (i < ticks.length - 1) {
        nextElement = new DomElement(ticks[i + 1])
      }

      // left most element
      if (i === 0 && position <= currentPosition + currentWidth) {
        return {
          value: currentValue,
          position: MARGIN_TICK - this._grabPosition
        }
      }

      // right most element
      if (!nextElement && position >= currentPosition) {
        return {
          value: currentValue,
          position: currentPosition + (currentWidth - MARGIN_TICK) - this._grabPosition - 1
        }
      }

      if (position >= currentPosition && position < (currentPosition + currentWidth)) {
        return {
          value: currentValue,
          position: currentPosition + (0.5 * currentWidth) - this._grabPosition
        }
      }

      currentPosition += currentWidth
    }

    throw new Error("Could not determine snap position")
  }

  /**
   * Gets the next value in the given direction with regards to snapping.
   * @param {value} The current value.
   * @param {direction} The direction (positive or negative integer).
   * @returns The next value.
   * @private
   */
  protected _getNextValue(value: number, direction: number) {
    const ticks = this._ticksWrapper.element.children

    for (let i = 0; i < ticks.length; i++) {
      const currentElement = new DomElement(ticks[i])
      let currentVal = parseFloat(currentElement.getAttribute("data-value")!)

      if (value === currentVal) {
        let index = clamp(i + direction, 0, ticks.length - 1)
        value = parseFloat(ticks[index].getAttribute("data-value")!)
      }
    }

    return value
  }

  protected _updateTickState() {
    if (this._ticksWrapper.element.childNodes.length > 1) {
      let activeTick = this._ticksWrapper.find(`.${CLASS_TICK_ACTIVE}`)
      if (activeTick) {
        activeTick.removeClass(CLASS_TICK_ACTIVE)
      }
      let newActiveTick = this._ticksWrapper.find(`.${CLASS_TICK}[data-value='${this.value}']`)
      if (newActiveTick) {
        newActiveTick.addClass(CLASS_TICK_ACTIVE)
      }
    }
  }

  protected _adjustTickLabelPosition(
    tickItem: Element,
    left: boolean
  ) {
    const label = new DomElement(tickItem.querySelector(`.${CLASS_TICK_LABEL}`)!)

    let dummyElement = new DomElement("span")
      .addClass(CLASS_TICK_LABEL)
      .setAttribute("style", "visibility: hidden; display: inline-block;")
      .setHtml(label.innerText)

    this._rangeContainer.appendChild(dummyElement)

    let width = dummyElement.element.clientWidth / 2
    this._rangeContainer.removeChild(dummyElement)

    const floatPosition = left ? "left" : "right"

    if (width < MARGIN_TICK) {
      // center small items on the tick
      label.setAttribute("style", `${floatPosition}: ${MARGIN_TICK - Math.floor(width)}px; text-align: ${floatPosition};`)
    }
  }

  protected _formatOutput(value: number, short: boolean) {
    if (this._formatter) {
      return this._formatter(value, short)
    }

    const str = parseFloat(value.toFixed(2))
    return str.toString()
  }

  /**
   * Validates and updates the range value.
   * @param {value} the new value to set.
   * @param {update} true if the UI should be updated; otherwise false.
   * @param {animate} true if the UI update should be animated; otherwise false.
   * @private
   */
  protected _setValue(
    value: number,
    update = true,
    animate = false
  ) {
    let val = clamp(value, this._minValue, this._maxValue)
    let position

    if (this._ticksWrapper.element.childNodes.length > 1) {
      const snapValue = this._getSnapValue(val)
      position = snapValue.position
      val = snapValue.value
    } else {
      position = (this._trackWidth / this._trackValueTotal) * (value - this._minValue)
    }

    // If the calculation failed, fall back to the first tick position and disable the component
    if (!position) {
      position = this._getSnapPosition(val).position
      this.disable()
    }

    this.element.value = String(val)

    if (this._thumbValue) {
      this._thumbValue.setHtml(this._formatOutput(val, true))
    }

    if (this._outputLabel) {
      this._outputLabel.setHtml(this._formatOutput(val, false))
    }

    if (update) {
      this._setPosition(position, false, false, animate)
      this._updateTickState()
    }

    this.dispatchEvent("input")
  }

  /**
   * Sets the value of the range slider.
   */
  set value(value: number) {
    this._setValue(value, true, true)
  }

  /**
   * Gets the current value.
   */
  get value() {
    return parseFloat(this.element.value)
  }

  /**
   * Force the component to re-layout itself.
   */
  public layout() {
    this._grabPosition = Math.round(this._rangeThumb.element.offsetWidth / 2)
    const tickItems = this._rangeContainer.element.querySelectorAll(`.${CLASS_TICK}`)
    const ticksOffset = tickItems && tickItems.length > 0 ? (2 * MARGIN_TICK) : MARGIN_TICK

    this._trackWidth = this._rangeTrack.element.offsetWidth - ticksOffset

    this._trackPositionMin = 0
    this._trackPositionMax = this._rangeTrack.element.clientWidth - this._rangeThumb.element.offsetWidth + 1
    this._trackLeftPosition = this._rangeTrack.element.getBoundingClientRect().left + MARGIN_TICK

    let itemCount = tickItems.length - 1

    this._itemWidth = this._trackWidth / itemCount
    const outerItemsWidth = (this._itemWidth * 0.5) + MARGIN_TICK

    for (let i = 0; i <= itemCount; i++) {
      let width = this._itemWidth

      if (i === 0 || i === itemCount) {
        width = outerItemsWidth
      }

      let item = new DomElement(tickItems[i])
      item.setAttribute("style", `width: ${Math.floor(width)}px;`)
    }

    // adjust first and last label positions
    if (tickItems.length > 1) {
      this._adjustTickLabelPosition(tickItems[0], true)
      this._adjustTickLabelPosition(tickItems[tickItems.length - 1], false)
    }

    // update the value
    this._setValue(parseFloat(this.element.value), true, false)
  }

  /**
   * Destroys the components and frees all references.
   */
  public destroy() {
    window.removeEventListener("resize", this._resizeHandler)
    window.removeEventListener("orientationchange", this._resizeHandler);

    (this as any)._downHandler = null;
    (this as any)._moveHandler = null;
    (this as any)._endHandler = null;
    (this as any)._focusHandler = null;
    (this as any)._blurHandler = null;

    (this as any).element = null;
    (this as any)._rangeContainer = null;
    (this as any)._wrapperElement = null
  }

  /**
   * @deprecated use destroy() instead.
   * @todo remove in version 2.0.0
   */
  public destoy() {
    this.destroy()
  }

  /**
   * Sets the component to the enabled state.
   */
  public enable() {
    this.element.removeAttribute("disabled")
    this._wrapperElement.removeClass(CLASS_DISABLED)

    this._rangeContainer.element.addEventListener("mousedown", this._downHandler)
    this._rangeContainer.element.addEventListener("touchstart", this._downHandler)
    this._rangeContainer.element.addEventListener("keydown", this._keydownHandler)
    this._rangeContainer.element.addEventListener("focus", this._focusHandler)
    this._rangeContainer.element.addEventListener("blur", this._blurHandler)
  }

  /**
   * Sets the component to the disabled state.
   */
  public disable() {
    this.element.setAttribute("disabled", "")
    this._wrapperElement.addClass(CLASS_DISABLED)

    this._rangeContainer.element.removeEventListener("mousedown", this._downHandler)
    this._rangeContainer.element.removeEventListener("mouseup", this._endHandler)
    this._rangeContainer.element.removeEventListener("mousemove", this._moveHandler)

    this._rangeContainer.element.removeEventListener("touchstart", this._downHandler)

    this._rangeContainer.element.removeEventListener("focus", this._focusHandler)
    this._rangeContainer.element.removeEventListener("blur", this._blurHandler)
  }
}

export function init() {
  searchAndInitialize<HTMLInputElement>("input[type='range']", (e) => {
    new Range(e)
  })
}

export default Range
