import anime from "animejs"
import DomElement from "../DomElement"

const CLASS_ITEMS = ".toolbar__item"
const CLASS_SHOW = "item--show"

const ANIMATION_START_DELAY = 100
const ANIMATION_OFFSET = 50

/**
 * Toolbar component. Use this component to show and hide the
 * individual toolbar items.
 */
class Toolbar extends DomElement {

  /**
   * Makes the toolbar items visible.
   */
  public show() {
    let delay = ANIMATION_START_DELAY
    let items = this.element.querySelectorAll(CLASS_ITEMS)

    let timeline = anime.timeline()
    for (let index = 0; index < items.length; index++) {
      timeline.add({
        targets: items[index],
        duration: 0,
        offset: delay,
        complete: () => {
          items[index].classList.add(CLASS_SHOW)
        }
      })
      delay += ANIMATION_OFFSET
    }
  }

  /**
   * Hides the toolbar items.
   */
  public hide() {
    let delay = ANIMATION_START_DELAY
    let items = this.element.querySelectorAll(CLASS_ITEMS)

    let timeline = anime.timeline()
    for (let index = items.length - 1; index >= 0; index--) {
      timeline.add({
        targets: items[index],
        duration: 0,
        offset: delay,
        complete: () => {
          items[index].classList.remove(CLASS_SHOW)
        }
      })
      delay += ANIMATION_OFFSET
    }
  }

  /**
   * Toggles the toolbar items visibility.
   */
  public toggle() {
    if (this.element.querySelectorAll(`.${CLASS_SHOW}`).length === 0) {
      this.show()
    } else {
      this.hide()
    }
  }
}

export default Toolbar
