/**
 * Calls the callback function when the document has been completely parsed.
 * @param {callback} value The callback function to execute.
 */
export function onDocumentReady(callback: (e?: Event) => void) {
  function completed() {
    document.removeEventListener("DOMContentLoaded", completed, false)
    window.removeEventListener("load", completed, false)
    callback()
  }

  if (document.readyState === "complete") {
    setTimeout(callback)
  } else {

    document.addEventListener("DOMContentLoaded", completed, false)

    // A fallback to window.onload, that will always work
    window.addEventListener("load", completed, false)
  }
}

/**
 * Searches for elements with the given selector and calls the callback
 * function if the `data-init` attribute is present on the element.
 * @param {selector} value The query.
 * @param {callback} value The callback function to initialize the element.
 * @param {function} initSelector The inititalization element selector function.
 */
export function searchAndInitialize<
  K extends keyof HTMLElementTagNameMap
  >(
    selector: K,
    callback: (el: HTMLElementTagNameMap[K]) => void,
    initSelector?: (el: HTMLElementTagNameMap[K]) => Element
  ): void
export function searchAndInitialize<
  E extends Element
  >(
    selector: string,
    callback: (el: E) => void,
    initSelector?: (el: E) => Element
  ): void
export function searchAndInitialize(
  selector: string,
  callback: (el: Element) => void,
  initSelector?: (el: Element) => Element
): void {
  if (!callback) {
    throw new Error("The callback cannot be undefined")
  }

  let elements = document.querySelectorAll(selector) as NodeListOf<Element>

  for (let e of elements) {

    let initElement: Element = e

    if (initSelector) {
      initElement = initSelector(e)
    }

    if (initElement.getAttribute("data-init") === "auto") {
      callback(e)
    }
  }
}

/**
 * Returns a number whose value is limited to the given range.
 *
 * Example: limit the output of this computation to between 0 and 255
 * Utils.clamp(number, 0, 255)
 *
 * @param {Number} value The number to clamp
 * @param {Number} min The lower boundary of the output range
 * @param {Number} max The upper boundary of the output range
 * @returns A number in the range [min, max]
 * @type Number
 */
export function clamp(value: number, min: number, max: number) {
  return Math.min(Math.max(value, min), max)
}

/**
 * A polyfill for Event.preventDefault().
 * @param {Event} event - The event to prevent the default action.
 */
export function preventDefault(event: Event) {
  if (event.preventDefault) {
    event.preventDefault()
  } else {
    event.returnValue = false
  }
}

/**
 * A polyfill for Node.remove().
 * @param {Node} node - The node to remove.
 */
export function remove(node: Node) {
  if (!node || !node.parentNode) {
    return
  }

  node.parentNode.removeChild(node)
}

/**
 * A simple polyfill for the Array.find() method.
 * @param {Array} array - The array to search in.
 * @param {function} expression - The expression to evaluate. Must return true if the element matches.
 */
export function find<T = any>(
  array: T[] | { length: number, [i: number]: T },
  expression: (item: T) => boolean
) {
  for (let i = 0; i < array.length; i++) {
    let item = array[i]
    if (expression(item) === true) {
      return item
    }
  }

  return undefined
}

/**
 * Checks the useragent and returns the Microsoft Internet Explorer / Edge version.
 * If another browser is detected 0 is returned.
 */
export function internetExplorerOrEdgeVersion(userAgent: string = navigator.userAgent) {
  // handle IE and Edge
  const ieOrEdge = userAgent.search(/MSIE |Edge[/]/)
  if (ieOrEdge > 0) {
    return parseInt(userAgent.substring(ieOrEdge + 5, userAgent.indexOf(".", ieOrEdge)), 10)
  }
  // handle IE11
  if (userAgent.indexOf("Trident/") > 0) {
    const rv = userAgent.indexOf("rv:")
    return parseInt(userAgent.substring(rv + 3, userAgent.indexOf(".", rv)), 10)
  }

  return 0
}

/**
 * Tries to move a child element to the top by scrolling the parent element, if it is not already fully visible.
 */
export function scrollIntoView(child: HTMLElement) {
  const parent = child.parentNode as HTMLElement
  const parentRect = parent.getBoundingClientRect()
  const childRect = child.getBoundingClientRect()
  const isFullyVisible = childRect.top >= parentRect.top && childRect.bottom <= parentRect.top + parent.clientHeight

  if (!isFullyVisible) {
    parent.scrollTop = childRect.top + parent.scrollTop - parentRect.top
  }
}
