import DomElement from "../DomElement"
import { searchAndInitialize, remove } from "../Utils"
import { getAttributeReference } from "../DomFunctions"
import { tryGetData, createLegendItem, isColor, removeAllChildren, ChartData, ChartAxis } from "./ChartFunctions"

import anime from "animejs"

const QUERY_DETAIL_RIGHT = ".detail-right"
const QUERY_DETAIL_BOTTOM = ".detail-bottom"
const QUERY_PROGRESS = ".bar-chart__progress"

const CLASS_UNLIMITED = "bar-chart-horizontal--unlimited"
const CLASS_LIMITED = "bar-chart-horizontal--limited"

const CLASS_DETAIL_VALUE = "value"
const CLASS_DETAIL_UNIT = "unit"

const CLASS_INDICATOR = "indicator"
const CLASS_INDICATOR_WRAPPER = "indicator-wrapper"

const CLASS_TOOLTIP = "tooltip"
const CLASS_TOOLTIP_MULTILINE = "tooltip--multiline"

const ANIMATION_DURATION = 500

/**
 * Bar Chart Horizontal Component.
 */
class BarChartHorizontal extends DomElement<HTMLElement> {
  private _data!: ChartData

  private _legendItems: HTMLElement[]
  private _progessWrapper!: HTMLElement

  private _unit!: string
  private _maxValue!: number
  private _precision!: number

  private _isUnlimited!: boolean
  private _isLimited!: boolean

  private _detailRight!: HTMLElement
  private _legend!: HTMLElement

  /**
   * Creates and initializes the bar chart horizontal component.
   * @param {DomElement} - root element of the chart.
   */
  constructor(element: HTMLElement, data?: ChartData) {
    super(element)

    if (data) {
      this._data = data
    }

    this._legendItems = []

    this._initialize()
  }

  protected _initialize() {
    this._unit = this.getAttribute("data-unit") || ""
    this._maxValue = parseFloat(this.getAttribute("data-max")!)
    this._precision = parseInt(this.getAttribute("data-precision")!, 10) || 0

    this._isUnlimited = this.hasClass(CLASS_UNLIMITED)
    this._isLimited = this.hasClass(CLASS_LIMITED)

    this._progessWrapper = this.element.querySelector(QUERY_PROGRESS)! as HTMLElement

    if (this._isLimited === true) {
      this._detailRight = this.element.querySelector(QUERY_DETAIL_BOTTOM)! as HTMLElement
    } else {
      this._detailRight = this.element.querySelector(QUERY_DETAIL_RIGHT)! as HTMLElement
    }

    if (this._isUnlimited === false && this._isLimited === false) {
      this._legend = getAttributeReference(this.element, "data-legend")!
    }

    if (!this._data) {
      this._data = tryGetData(this.element)
    }

    this._render()
  }

  protected _render() {
    let dataOne = this._data[0]
    let dataTwo = this._data[1]

    let tooltip = this._isLimited === false ? this._getTooltipContent(this._data) : undefined

    let animatedValueElement: Element | undefined

    // Cleanup
    removeAllChildren(this._detailRight)
    removeAllChildren(this._progessWrapper)

    // Clear only own legend items
    for (let item of this._legendItems) {
      remove(item)
    }
    this._legendItems = []

    if (dataOne) {
      if (this._isUnlimited === false || (this._isUnlimited === true && !dataTwo)) {

        let valElement = animatedValueElement = this._createValueElement(dataOne)
        this._detailRight.appendChild(valElement)

        if (this._isLimited === false) {
          const separatorElement = new DomElement("div")
            .addClass(CLASS_DETAIL_UNIT)
            .element as HTMLElement
          separatorElement.innerText = ` ${this._unit}`

          this._detailRight.appendChild(separatorElement)
        }
      }

      // Add the indicator
      let indicator = this._addIndicator(dataOne, tooltip)
      this._animateIndicator(indicator, 0)

      // Animate the value if required
      if (animatedValueElement && this._isLimited === true) {
        this._animateValueElement(animatedValueElement as HTMLElement, dataOne.value)
      }

      // Add the legend
      if (this._legend) {
        const legendItem = createLegendItem(dataOne)
        this._legend.appendChild(legendItem)
        this._legendItems.push(legendItem)

        this._animateLegend(legendItem, 0)
      }
    }

    if (dataTwo) {
      let valElement = this._createValueElement(dataTwo)

      let unitElement = new DomElement("div")
        .addClass(CLASS_DETAIL_UNIT)
        .element as HTMLElement
      unitElement.innerText = ` ${this._unit}`

      this._detailRight.appendChild(valElement)
      this._detailRight.appendChild(unitElement)

      // Add the indicator
      let indicator = this._addIndicator(dataTwo, tooltip)
      this._animateIndicator(indicator, ANIMATION_DURATION)

      // Add the legend
      if (this._legend) {
        const legendItem = createLegendItem(dataTwo)
        this._legend.appendChild(legendItem)
        this._legendItems.push(legendItem)

        this._animateLegend(legendItem, ANIMATION_DURATION)
      }
    }

    if (this._isLimited === true) {
      let valElement = this._createValueElement({ value: this._maxValue })

      let unitElement = new DomElement("div")
        .addClass(CLASS_DETAIL_UNIT)
        .element as HTMLElement
      unitElement.innerText = ` ${this._unit}`

      this._detailRight.appendChild(valElement)
      this._detailRight.appendChild(unitElement)
    }
  }

  private _animateValueElement(animatedValueElement: HTMLElement, toValue: number) {
    let counter = { var: 0 }
    anime({
      targets: counter,
      var: toValue,
      duration: ANIMATION_DURATION,
      easing: "easeOutQuint",
      round: 1,
      update: () => {
        animatedValueElement!.innerText = `${counter.var}`
      }
    })
  }

  private _animateIndicator(indicatorWrapper: HTMLElement, animationOffset: number) {
    const indicator = indicatorWrapper.getElementsByClassName("indicator")[0] as HTMLElement
    const indicatorWidth = indicator.scrollWidth
    indicator.style.width = "0px"

    anime({
      targets: indicator,
      duration: ANIMATION_DURATION,
      width: indicatorWidth + "px",
      easing: "easeInOutQuint",
      delay: animationOffset,
      complete: () => {
        indicator.style.width = ""
      }
    })
  }

  private _animateLegend(legendItem: HTMLElement, animationOffset: number) {
    legendItem.style.opacity = "0"
    anime({
      targets: legendItem,
      duration: ANIMATION_DURATION,
      opacity: 1,
      easing: "easeInOutQuint",
      delay: animationOffset,
      complete: () => {
        legendItem.style.opacity = null
      }
    })
  }

  protected _createValueElement(data: { value: number | string }) {
    let unlimitedPrefix = ""

    if (this._isUnlimited === true) {
      unlimitedPrefix = "+"
    }

    let value: string | number = parseFloat((data.value as string))

    if (value <= 0) {
      if (this._precision === 0) {
        value = "0"
      } else {
        value = "."

        for (let i = 0; i < this._precision; i++) {
          value += "0"
        }
      }
    } else {
      value = value.toFixed(this._precision)
    }

    const valueElement = new DomElement("div")
      .addClass(CLASS_DETAIL_VALUE)
      .element as HTMLElement
    valueElement.innerText = `${unlimitedPrefix}${value}`
    return valueElement
  }

  protected _addIndicator(data: ChartAxis, tooltip?: string) {
    let width = ((100.0 / this._maxValue) * data.value)

    let indicator = new DomElement("div")
      .addClass(CLASS_INDICATOR)

    if (isColor(data.color) === true) {
      indicator.setAttribute("style", `background-color: ${data.color};`)
    } else {
      indicator.addClass(data.color)
    }

    let indicatorWrapper = new DomElement("div")
      .addClass(CLASS_INDICATOR_WRAPPER)
      .setAttribute("style", `width: ${width}%`)
      .appendChild(indicator)
      .setAttribute("onclick", "void(0)")

    if (tooltip && tooltip !== "") {
      indicatorWrapper
        .addClass(CLASS_TOOLTIP)
        .addClass(CLASS_TOOLTIP_MULTILINE)
        .setAttribute("aria-label", tooltip)
    }

    this._progessWrapper.appendChild(indicatorWrapper.element)
    return indicatorWrapper.element as HTMLElement
  }

  protected _getTooltipContent(dataList: ChartData) {
    let tooltip = ""
    for (let data of dataList) {
      tooltip += `${data.title}: ${data.value} ${this._unit}\n`
    }

    return tooltip.trim()
  }

  /**
   * Updates the bar chart with the specified data definitions.
   * @param {Array} - bar chart data definitions.
   */
  public update(data: ChartData) {
    if (data) {
      this._data = data
    }

    this._render()
  }

  /**
   * Removes all event handlers and clears references.
   */
  public destroy() {
    (this as any)._data = undefined

    removeAllChildren(this._detailRight)
    removeAllChildren(this._progessWrapper);

    (this as any)._detailRight = undefined;
    (this as any)._progessWrapper = undefined

    for (let item of this._legendItems) {
      remove(item)
    }

    (this as any)._legendItems = undefined;
    (this as any)._legend = undefined
  }

  /**
   * @deprecated use destroy() instead.
   * @todo remove in version 2.0.0
   */
  public destory() {
    this.destroy()
  }
}

export function init() {
  searchAndInitialize<HTMLElement>(".bar-chart-horizontal", (e) => {
    new BarChartHorizontal(e)
  })
}

export default BarChartHorizontal
