import anime from "animejs"
import Popper from "popper.js"

import DomElement from "../DomElement"
import { addClass, hasClass, removeClass, isHidden, parentWithClass } from "../DomFunctions"

const CLASS_OPEN = "is-open"
const CLASS_MENU = "js-flyout"
const CLASS_TABS = "tabs"

const ANIMATION_OPEN = 300

/**
 * A component for the flyout menu.
 */
class MenuFlyout extends DomElement {
  private _clickHandler: (e: Event) => void
  private _windowClickHandler: (e: MouseEvent | TouchEvent) => void

  private _animationDuration = ANIMATION_OPEN

  private _dynamicPlacement = false

  private _hiddenIndicator?: HTMLElement
  private _flyoutElement!: HTMLElement

  private _popperInstance?: Popper

  /**
   * Creates and initializes the flyout component.
   * @param element - The root element of the flyout menu component.
   */
  constructor(element: Element) {
    super(element)

    this._clickHandler = this._handleClick.bind(this)
    this._windowClickHandler = this._handleWindowClick.bind(this)

    this._initialize()
  }

  /**
   * Initializes the flyout component.
   * @private
   */
  protected _initialize() {
    let dataTarget = this.element.getAttribute("data-target")
    if (dataTarget === null || dataTarget === "") {

      /* tslint:disable:no-console */
      console.error("A flyout menu element requires a 'data-target' that specifies the element to collapse")
      console.info(this.element)
      /* tslint:enable:no-console */

      return
    }

    if (this._useDynamicPlacement()) {
      this._dynamicPlacement = true
    }

    let hiddenTarget = this.element.getAttribute("data-hidden")
    if (hiddenTarget !== null && hiddenTarget !== "") {
      this._hiddenIndicator = document.querySelector(hiddenTarget) as HTMLElement || undefined
    }

    this._initFlyoutElement(dataTarget)
    this.element.addEventListener("click", this._clickHandler)
  }

  private _initFlyoutElement(dataTarget: string) {
    this._flyoutElement = document.querySelector(dataTarget)! as HTMLElement
    this._flyoutElement.style.opacity = "0"
    this._flyoutElement.style.transform = "translateY(-20px)"
  }

  protected _handleClick() {
    this.toggle()
  }

  protected _handleWindowClick(event: MouseEvent | TouchEvent) {
    let target = event.target as HTMLElement

    if (parentWithClass(target, CLASS_MENU) === this._flyoutElement) {
      return false
    }

    while (target !== this.element && target.parentElement) {
      target = target.parentElement
    }

    if (target !== this.element) {
      this.close()
      return false
    }

    return true
  }

  protected _useDynamicPlacement() {
    return parentWithClass(this.element, CLASS_TABS)
  }

  protected _openMenu(el: HTMLElement) {
    anime.remove(el)

    if (this._dynamicPlacement === true) {
      const popperOptions: Popper.PopperOptions = {
        placement: "bottom",
        modifiers: {
          flip: {
            enabled: false
          }
        },
        eventsEnabled: false
      }

      this._popperInstance = new Popper(this.element, this._flyoutElement, popperOptions)
    }

    anime({
      targets: el,
      duration: this._animationDuration,
      easing: "cubicBezier(0.550, 0.085, 0.320, 1)",
      opacity: 1,
      translateY: "0px",
      begin: () => {
        el.style.display = "block"
      },
      complete: () => {
        addClass(el, CLASS_OPEN)
      }
    })

    // set aria expanded
    el.setAttribute("aria-expanded", "true")

    this.dispatchEvent("opened")
  }

  protected _closeMenu(el: HTMLElement) {
    anime.remove(el)

    if (this._popperInstance) {
      this._popperInstance.destroy()
      this._popperInstance = undefined
    }

    anime({
      targets: el,
      duration: this._animationDuration,
      easing: "cubicBezier(0.550, 0.085, 0.320, 1)",
      opacity: 0,
      translateY: "-20px",
      complete: () => {
        el.style.display = "none"
        removeClass(el, CLASS_OPEN)
      }
    })

    // set aria expanded
    el.setAttribute("aria-expanded", "false")

    this.dispatchEvent("closed")
  }

  /**
   * Sets the opening animation duration.
   * @param {durationInSeconds} - The animation duration in seconds.
   */
  set animationDuration(durationInSeconds: number) {
    this._animationDuration = durationInSeconds
  }

  /**
   * Opens the flyout menu.
   * @fires Modal#opened
   */
  public open() {
    if (this._hiddenIndicator && isHidden(this._hiddenIndicator, false) === true) {
      return
    }

    if (hasClass(this.element, CLASS_OPEN) === true) {
      return
    }

    addClass(this.element, CLASS_OPEN)
    this._openMenu(this._flyoutElement)

    setTimeout(() => {
      window.addEventListener("click", this._windowClickHandler)
      window.addEventListener("touchend", this._windowClickHandler)
    }, 50)
  }

  /**
   * Closes the flyout menu.
   * @fires Modal#closed
   */
  public close() {
    if (this._hiddenIndicator && isHidden(this._hiddenIndicator, false) === true) {
      return
    }

    if (hasClass(this.element, CLASS_OPEN) === false) {
      return
    }

    removeClass(this.element, CLASS_OPEN)

    window.removeEventListener("click", this._windowClickHandler)
    window.removeEventListener("touchend", this._windowClickHandler)

    this._closeMenu(this._flyoutElement)
  }

  /**
   * Toggles the flyout menu.
   * @fires Modal#opened
   * @fires Modal#closed
   */
  public toggle() {
    if (hasClass(this.element, CLASS_OPEN) === false) {
      this.open()
    } else {
      this.close()
    }
  }

  /**
   * Removes all event handlers and clears references.
   */
  public destroy() {
    (this as any)._flyoutElement = null

    window.removeEventListener("click", this._windowClickHandler)
    window.removeEventListener("touchend", this._windowClickHandler)

    if (this._clickHandler) {
      this.element.removeEventListener("click", this._clickHandler)
    }

    if (this._popperInstance) {
      this._popperInstance.destroy()
      this._popperInstance = undefined
    }

    (this as any)._clickHandler = null;
    (this as any)._windowClickHandler = null;
    (this as any).element = null
  }

  /**
   * Fired when the flyout menu is opened by the anchor link or using the
   * {@link MenuFlyout#open} method.
   * @event MenuFlyout#opened
   * @type {object}
   */

  /**
   * Fired when the flyout menu is closed by the user or using the
   * {@link MenuFlyout#close} method.
   * @event MenuFlyout#closed
   * @type {object}
   */
}

export function init() {
  let elements = document.querySelectorAll("[data-toggle='flyout']")
  for (let e of elements) {
    if (e.getAttribute("data-init") === "auto") {
      new MenuFlyout(e)
    }
  }
}

export default MenuFlyout
