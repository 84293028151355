/* Polyfills */
import "./polyfills/Math.sign"
import "./polyfills/startsWith"

import * as sdxExports from "./src/sdx"

export interface SDXGlobal {
  VERSION: string

  LoaderBar: typeof sdxExports.LoaderBar

  Select: typeof sdxExports.Select
  InputField: typeof sdxExports.InputField
  Textarea: typeof sdxExports.Textarea
  Autocomplete: typeof sdxExports.Autocomplete

  ProgressLight: typeof sdxExports.ProgressLight
  ProgressFull: typeof sdxExports.ProgressFull

  Range: typeof sdxExports.Range
  Modal: typeof sdxExports.Modal
  Toolbar: typeof sdxExports.Toolbar
  Notification: typeof sdxExports.Notification

  Collapse: typeof sdxExports.Collapse
  Accordion: typeof sdxExports.Accordion

  MenuFlyout: typeof sdxExports.MenuFlyout

  Navigation: typeof sdxExports.Navigation
  NavigationSide: typeof sdxExports.NavigationSide
  SearchInput: typeof sdxExports.SearchInput
  EmptyState: typeof sdxExports.EmptyState
  Carousel: typeof sdxExports.Carousel

  Table: typeof sdxExports.Table

  PieChart: typeof sdxExports.PieChart
  BarChartHorizontal: typeof sdxExports.BarChartHorizontal
  BarChartVertical: typeof sdxExports.BarChartVertical
}

declare global {
  interface Window {
    sdx: SDXGlobal
  }
}

let sdx = {
  ...sdxExports,
  ...(window.sdx as any) || {},
  VERSION: "$VERSION$"
}

window.sdx = sdx

sdxExports.utils.onDocumentReady(() => {
  sdxExports.initInputField()
  sdxExports.initTextarea()
  sdxExports.initSelect()
  sdxExports.initLoaderBar()
  sdxExports.initAutocomplete()

  sdxExports.initProgressLight()
  sdxExports.initProgressFull()

  sdxExports.initRange()
  sdxExports.initModal()

  sdxExports.initAccordion()
  sdxExports.initCollapse()
  sdxExports.initMenuFlyout()

  sdxExports.initNavigation()
  sdxExports.initNavigationSide()
  sdxExports.initSearchInput()
  sdxExports.initEmptyState()
  sdxExports.initCarousel()
  sdxExports.initTable()

  sdxExports.initPieChart()
  sdxExports.initBarChartHorizontal()
  sdxExports.initBarChartVertical()
})
