import DomElement from "../DomElement"
import { text } from "../DomFunctions"

export interface ChartLabel {
  title: string
  color: string
}

export interface ChartAxis extends ChartLabel {
  value: number
}

export type ChartData = ChartAxis[]

const QUERY_DATA = ".js-data"

export function tryGetData(element: HTMLElement): ChartData {
  let data = []
  let elements = element.querySelectorAll(QUERY_DATA)

  for (let entry of elements) {
    let value = parseFloat(entry.getAttribute("data-value")!)
    let color = entry.getAttribute("data-color")!
    let title = text(entry)

    let item = {
      title,
      value,
      color
    }

    data.push(item)
  }

  return data
}

export function removeAllChildren(node: Node) {
  while (node.firstChild) {
    node.removeChild(node.firstChild)
  }
}

export function createLegendItem(data: ChartLabel) {
  const bullet = new DomElement<HTMLSpanElement>("span")
    .addClass("bullet")

  if (isColor(data.color) === true) {
    bullet.setAttribute("style", `background-color: ${data.color};`)
  } else {
    bullet.addClass(data.color)
  }

  const caption = new DomElement<HTMLSpanElement>("span")
    .setHtml(data.title)

  return new DomElement<HTMLLIElement>("li")
    .appendChild(bullet)
    .appendChild(caption)
    .element
}

export function isColor(str: string) {
  const pattern = /^#/i
  return pattern.test(str)
}
