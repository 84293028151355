import anime from "animejs"
import { searchAndInitialize, clamp } from "../Utils"
import DomElement from "../DomElement"

const CLASS_BAR = ".progress-light__bar"
const CLASS_PROGRESS = ".bar__progress"
const CLASS_PROGRESS_COMPLETED = "bar__progress--complete"
const CLASS_TICK = "bar__tick"
const CLASS_PAGE_CURRENT = ".detail__currentpage"
const CLASS_PAGE_TOTAL = ".detail__totalpage"

const CLASS_DISABLED = "arrow--disabled"
const CLASS_BUTTON_LEFT = ".arrow--left"
const CLASS_BUTTON_RIGHT = ".arrow--right"

/**
 * Light progress bar component
 */
class ProgressLight extends DomElement {
  private _buttonClickHandler!: (event: Event) => void
  private _animationCompletedHandler!: (event: Event) => void

  private _barElement!: DomElement<Element>
  private _progressElement!: DomElement<Element>
  private _pageCurrentElement!: DomElement<Element>
  private _pageTotalElement!: DomElement<Element>
  private _buttonLeft!: DomElement<Element>
  private _buttonRight!: DomElement<Element>

  private _minValue!: number
  private _total!: number
  private _value!: number

  private _itemWidth?: number

  /**
   * Creates and initializes the ProgressLight component.
   * @param {DomElement} - The root element of the ProgressLight component.
   */
  constructor(element: Element) {
    super(element)
    this._initialize()
  }

  /**
   * Initializes the loader bar component.
   * @private
   */
  protected _initialize() {

    this._buttonClickHandler = this._handleButtonClick.bind(this)
    this._animationCompletedHandler = this._handleAnimationCompleted.bind(this)

    this._barElement = this.find(CLASS_BAR)!
    this._progressElement = this.find(CLASS_PROGRESS)!
    this._pageCurrentElement = this.find(CLASS_PAGE_CURRENT)!
    this._pageTotalElement = this.find(CLASS_PAGE_TOTAL)!
    this._buttonLeft = this.find(CLASS_BUTTON_LEFT)!
    this._buttonRight = this.find(CLASS_BUTTON_RIGHT)!

    this._minValue = 1
    this._total = Math.max(parseInt(this.getAttribute("total") || "100", 10), this._minValue)
    this._value = clamp(parseInt(this.getAttribute("value") || "1", 10), this._minValue, this._total)

    this._layout()

    this._addTicks()
    this._update(false)

    this.enable()
  }

  protected _addTicks() {
    for (let i = 1; i < this._total; i++) {
      const position = this._itemWidth! * i

      let tickElement = new DomElement("div")
        .addClass(CLASS_TICK)
        .setAttribute("style", `left: ${position}%`)

      this._barElement.prependChild(tickElement)
    }
  }

  protected _update(animate = true) {
    this._pageCurrentElement.setHtml(this._value.toString())
    this._pageTotalElement.setHtml(this._total.toString())

    let position = this._value * this._itemWidth!

    // Add additional width to the last element to make sure
    // the rounded border on the left is filled as well
    if (this._value === this._total) {
      position += 5
    }

    if (this._value >= this._total) {
      this._buttonRight.addClass(CLASS_DISABLED)
    } else {
      this._buttonRight.removeClass(CLASS_DISABLED)
    }

    if (this._value <= this._minValue) {
      this._buttonLeft.addClass(CLASS_DISABLED)
    } else {
      this._buttonLeft.removeClass(CLASS_DISABLED)
    }

    const el = this._progressElement.element as HTMLElement
    if (animate) {
      anime({
        targets: this._progressElement.element,
        duration: 200,
        easing: "easeInOutQuint",
        width: this._barElement.element.clientWidth * position / 100,
        complete: () => {
          el.style.width = `${position}%`
          this._animationCompletedHandler(<Event>{})
        }
      })
    } else {
      el.style.width = `${position}%`
      this._animationCompletedHandler(<Event>{})
    }
  }

  protected _layout() {
    this._itemWidth = Math.floor(100 / this._total)
  }

  protected _handleButtonClick(event: Event) {
    if (event.target === this._buttonLeft.element) {
      this.value = this._value - 1
    } else if (event.target === this._buttonRight.element) {
      this.value = this._value + 1
    }
  }

  protected _handleAnimationCompleted() {
    if (this._value === this._total) {
      this._progressElement.addClass(CLASS_PROGRESS_COMPLETED)
    } else {
      this._progressElement.removeClass(CLASS_PROGRESS_COMPLETED)
    }
  }

  /**
   * Gets the current progress value in the range of 1..total.
   */
  get value() {
    return this._value
  }

  /**
   * Sets the current progress.
   * @param {number} - The progress in the range of 1..total.
   */
  set value(val) {
    this._value = clamp(val, this._minValue, this._total)
    this._update(true)

    this.dispatchEvent("changed")
  }

  /**
   * Gets the total progress value.
   */
  get total() {
    return this._total
  }

  /**
   * Sets the total progress value and updates the UI accordingly.
   * @param {number} - The total progress positive integer value.
   */
  set total(value) {
    if (this._total === value) {
      return
    }

    this._total = Math.max(value, this._minValue)
    this._value = clamp(this._value, this._minValue, this._total)

    // Clear the ticks
    for (let tick of this.element.querySelectorAll(`.${CLASS_TICK}`)) {
      this._barElement.element.removeChild(tick)
    }

    this._layout()
    this._addTicks()

    this._update(false)

    this.dispatchEvent("totalchanged")
  }

  /**
   * Enables the component.
   */
  public enable() {
    this._buttonLeft.element.addEventListener("click", this._buttonClickHandler)
    this._buttonRight.element.addEventListener("click", this._buttonClickHandler)
  }

  /**
   * Disables the component.
   */
  public disable() {
    this._buttonLeft.element.removeEventListener("click", this._buttonClickHandler)
    this._buttonRight.element.removeEventListener("click", this._buttonClickHandler)
  }
}

export function init() {
  searchAndInitialize(".progress-light", (e) => {
    new ProgressLight(e)
  })
}

export default ProgressLight
