import { searchAndInitialize } from "../Utils"
import DomElement from "../DomElement"
import * as Dom from "../DomFunctions"

const QUERY_HEADER = "thead th"

const CLASS_SORTED_ASCENDING = "js-ascending"
const CLASS_SORTED_DESCENDING = "js-descending"
const CLASS_ARROW = "arrow-icon"

export interface Comparer<T = any> {
  (item1: T, item2: T): number
}

/**
 * The Table component. Adds additional capabilities to standard HTML 5 tables.
 */
class Table extends DomElement {
  private _headerClickHandler: (e: Event) => void
  private _body: HTMLTableSectionElement
  private _rows: HTMLCollectionOf<HTMLTableRowElement>

  /**
   * Creates a new instance of the table component.
   */
  constructor(element: HTMLTableElement) {
    super(element)

    this._headerClickHandler = this._handleHeaderClick.bind(this)

    this._body = this.element.querySelector("tbody") as HTMLTableSectionElement
    this._rows = this._body.getElementsByTagName("tr")

    this._initialize()
  }

  protected _initialize() {
    for (let header of this.element.querySelectorAll(QUERY_HEADER)) {
      if (header.getAttribute("data-type")) {
        header.addEventListener("click", this._headerClickHandler)

        let arrowElement = new DomElement("div")
          .addClass(CLASS_ARROW)
          .element

        header.appendChild(arrowElement)
      }
    }
  }

  protected _handleHeaderClick(e: Event) {
    const th = e.target as HTMLTableHeaderCellElement
    this.sort(th)
  }

  /**
   * Sorts the table according to the specified table header element.
   * The column is sorted ascending by default if no direction is specified and no
   * existing sort order class is found in the markup.
   *
   * If the displayed data is not suitable for sorting `<td/>` elements can define a `data-value` attribute
   * which is then used for the data-source.
   *
   * @param {TableHeader} tableHeader The header element of the row to sort by.
   * @param {Number} direction The direction to sort, `1` for ascending, `-1` for descending order. This parameter is optional.
   * @param {function} equalityComparer The equiality comparer function to compare individual cell values.
   */
  public sort(
    tableHeader: HTMLTableHeaderCellElement,
    direction?: -1 | 1,
    equalityComparer?: Comparer
  ) {
    if (!tableHeader || tableHeader.tagName !== "TH") {
      throw new Error("The parameter 'tableHeader' must be a valid column header node")
    }

    if (direction !== 1 && direction !== -1 && direction) {
      throw new Error(`Parameter out of range, parameter 'direction' with value '${direction}' must be either -1, 1 or undefined`)
    }

    const columnIndex = tableHeader.cellIndex

    if (!equalityComparer) {
      let dataType = tableHeader.getAttribute("data-type")
      equalityComparer = this._getComparer(dataType!)
    }

    if (columnIndex >= this.element.querySelectorAll(QUERY_HEADER).length) {
      throw new Error("Column out of range")
    }

    for (let header of this.element.querySelectorAll(QUERY_HEADER)) {
      if (header !== tableHeader) {
        Dom.removeClass(header, CLASS_SORTED_ASCENDING)
        Dom.removeClass(header, CLASS_SORTED_DESCENDING)
      }
    }

    if (Dom.hasClass(tableHeader, CLASS_SORTED_ASCENDING)) {
      Dom.removeClass(tableHeader, CLASS_SORTED_ASCENDING)
      Dom.addClass(tableHeader, CLASS_SORTED_DESCENDING)

      direction = direction || -1
    } else {
      Dom.removeClass(tableHeader, CLASS_SORTED_DESCENDING)
      Dom.addClass(tableHeader, CLASS_SORTED_ASCENDING)
      direction = direction || 1
    }

    this._quicksort(columnIndex, 0, this._rows.length - 1, direction, equalityComparer)
  }

  protected _getCell(column: number, row: number) {
    return this._rows[row].cells[column]
  }

  protected _getRow(row: number) {
    return this._rows[row]
  }

  protected _getComparer(dataType: string): Comparer<string> {
    switch (dataType) {
      case "number": {
        // parse the string as a number
        return (a, b) => parseFloat(a) - parseFloat(b)
      }
      default: {
        // compare strings
        return (a, b) => {
          if (a < b) {
            return -1
          }
          if (a > b) {
            return 1
          }

          return 0
        }
      }
    }
  }

  protected _quicksort(
    column: number,
    left: number,
    right: number,
    direction: -1 | 1 = 1,
    equalityComparer: Comparer<string>
  ) {
    if (right - left > 0) {

      let partition = this._partition(column, left, right, direction, equalityComparer)

      if (left < partition - 1) {
        this._quicksort(column, left, partition - 1, direction, equalityComparer)
      }

      if (partition < right) {
        this._quicksort(column, partition, right, direction, equalityComparer)
      }
    }
  }

  protected _partition(
    column: number,
    left: number,
    right: number,
    direction: -1 | 1 = 1,
    equalityComparer: Comparer<string>
  ) {
    let pivot = this._getCell(column, Math.floor((right + left) / 2))
    let i = left
    let j = right

    while (i <= j) {
      while (this._equals(this._getCell(column, i), pivot, equalityComparer) * direction < 0) {
        i++
      }

      while (this._equals(this._getCell(column, j), pivot, equalityComparer) * direction > 0) {
        j--
      }

      if (i <= j) {
        this._swap(i, j)
        i++
        j--
      }
    }

    return i
  }

  protected _equals(
    a: HTMLElement,
    b: HTMLElement,
    equalityComparer: Comparer<string>
  ) {
    let dataA = a.getAttribute("data-value")
    let dataB = b.getAttribute("data-value")

    dataA = dataA || a.textContent || a.innerText
    dataB = dataB || b.textContent || b.innerText

    return equalityComparer(dataA, dataB)
  }

  protected _swap(i: number, j: number) {
    let tmpNode = this._body.replaceChild(this._getRow(i), this._getRow(j))
    const referenceRow = this._getRow(i)

    if (!referenceRow) {
      this._body.appendChild(tmpNode)
    } else {
      this._body.insertBefore(tmpNode, referenceRow)
    }
  }

  /**
   * Destroys the component and clears all references.
   */
  public destroy() {
    for (let header of this.element.querySelectorAll(QUERY_HEADER)) {
      header.removeEventListener("click", this._headerClickHandler)
    }

    (this as any)._headerClickHandler = null;
    (this as any)._body = null;
    (this as any)._rows = null
  }
}

export function init() {
  searchAndInitialize("table", (e) => {
    new Table(e)
  })
}

export default Table
