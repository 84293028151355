import { preventDefault } from "../Utils"
import anime from "animejs"
import DomElement from "../DomElement"
import { addClass, hasClass, removeClass, isHidden } from "../DomFunctions"

const CLASS_OPEN = "is-open"

const ANIMATION_OPEN = 300

/**
 * The Collapse component.
 */
class Collapse extends DomElement {
  private _hiddenIndicator!: HTMLElement
  private _collapsibleElements!: NodeListOf<HTMLElement>
  private _clickHandler: (e: Event) => void

  /**
   * Creates and initializes the Collapse component.
   * @param {DomElement} - The root element of the Collapse component.
   */
  constructor(element: HTMLElement) {
    super(element)

    this._clickHandler = this._handleClick.bind(this)
    this._initialize()
  }

  /**
   * Initializes the Collapse component.
   * @private
   */
  protected _initialize() {
    let dataTarget = this.element.getAttribute("data-target")
    if (dataTarget === null || dataTarget === "") {

      /* tslint:disable:no-console */
      console.error("A collapsible element requires a 'data-target' that specifies the element to collapse")
      console.info(this.element)
      /* tslint:enable:no-console */

      return
    }

    let hiddenTarget = this.element.getAttribute("data-hidden")
    if (hiddenTarget !== null && hiddenTarget !== "") {
      this._hiddenIndicator = document.querySelector(hiddenTarget)! as HTMLElement
    }

    this._collapsibleElements = document.querySelectorAll(dataTarget)
    this.element.addEventListener("click", this._clickHandler)
  }

  protected _handleClick(event: Event) {
    preventDefault(event)
    this.toggle()
  }

  /**
   * Toggles the collapseible.
   */
  public toggle() {
    if (this._hiddenIndicator && isHidden(this._hiddenIndicator, false) === true) {
      return
    }

    if (hasClass(this.element, CLASS_OPEN) === false) {
      addClass(this.element, CLASS_OPEN)

      for (let s of this._collapsibleElements) {
        this._openCollapse(s)
      }
    } else {
      removeClass(this.element, CLASS_OPEN)

      for (let s of this._collapsibleElements) {
        this._closeCollapse(s)
      }
    }
  }

  protected _openCollapse(el: HTMLElement) {
    anime.remove(el)

    el.style.display = "block"

    anime({
      targets: el,
      duration: ANIMATION_OPEN,
      height: el.scrollHeight + "px",
      easing: "cubicBezier(0.550, 0.085, 0.320, 1)",
      complete: () => {
        const domEl = new DomElement(el)
        domEl.addClass(CLASS_OPEN)
        domEl.setAttribute("style", "")
      }
    })

    // set aria expanded
    el.setAttribute("aria-expanded", "true")
  }

  protected _closeCollapse(el: HTMLElement) {
    anime.remove(el)

    anime({
      targets: el,
      duration: ANIMATION_OPEN,
      height: 0,
      easing: "cubicBezier(0.550, 0.085, 0.320, 1)",
      complete: () => {
        const domEl = new DomElement(el)
        domEl.removeClass(CLASS_OPEN)
        domEl.setAttribute("style", "")
      }
    })

    // set aria expanded
    el.setAttribute("aria-expanded", "false")
  }

  /**
   * Removes all event handlers and clears references.
   */
  public destroy() {
    (this as any)._collapsibleElements = null

    if ((this as any)._clickHandler) {
      this.element.removeEventListener("click", this._clickHandler)
    }

    (this as any).element = null
  }
}

export function init() {
  let elements = document.querySelectorAll("[data-toggle='collapse']")
  for (let e of elements) {
    if (e.getAttribute("data-init") === "auto") {
      new Collapse(e as HTMLElement)
    }
  }
}

export default Collapse
