import { searchAndInitialize, remove } from "../Utils"
import DomElement from "../DomElement"
import flatpickr from "flatpickr"

import { Italian } from "flatpickr/dist/l10n/it.js"
import { French } from "flatpickr/dist/l10n/fr.js"
import { German } from "flatpickr/dist/l10n/de.js"

flatpickr.localize(Italian)
flatpickr.localize(French)
flatpickr.localize(German)

const DEFAULTS_FLATPICKR = {
  wrap: true, // enable calendar toggle icon
  allowInput: true, // don't set input to readonly
  locale: "de", // German is default
  dateFormat: "d.m.Y", // 15.01.2017
  time_24hr: true
}

const CLASS_HAS_VALUE = "is-fixed"
const CLASS_MESSAGE = ".message"

/**
 * Input field component
 */
class InputField extends DomElement<HTMLInputElement> {
  private _changedHandler: () => void
  private _animationStartHandler: (e: AnimationEvent) => void
  private _flatpickrInstance: any
  private _datePickerOptions: any

  constructor(element: HTMLInputElement, datePickerOptions?: any) {
    super(element)

    this._changedHandler = this.onValueChanged.bind(this)
    this._animationStartHandler = this._onAnimationStart.bind(this)
    this._datePickerOptions = datePickerOptions
    this._initialize()
  }

  /**
   * Initializes the input field component.
   * @private
   */
  protected _initialize() {
    this.element.addEventListener("input", this._changedHandler)

    if (this.element.getAttribute("type") === "password") {
      this.element.addEventListener("animationstart", this._animationStartHandler)
    }

    this._initializeDatePicker()
    this.onValueChanged()
  }

  protected _initializeDatePicker() {
    const picker = this.element.parentElement
    if (!picker || !picker.classList.contains("flatpickr")) {
      return
    }
    if (!this._datePickerOptions) {
      try {
        this._datePickerOptions = JSON.parse(picker.dataset.options || "{}")
      } catch (e) {
        this._datePickerOptions = {}
        // tslint:disable-next-line:no-console
        console.warn("_initializeDatePicker JSON.parse failed", picker.dataset.options, e)
      }
    }
    this._flatpickrInstance = flatpickr(picker, Object.assign({}, DEFAULTS_FLATPICKR, this._datePickerOptions))
  }

  protected _destroyDatePicker() {
    if (this._flatpickrInstance) {
      this._flatpickrInstance.destroy()
    }
  }

  protected _onAnimationStart(e: AnimationEvent) {
    if (e.animationName === "onAutoFillStart") {
      this.onValueChanged(true)
    }
  }

  /**
   * Notifies the input field component that it's value has been changed.
   */
  public onValueChanged(force = false) {
    if (this.element.value && this.element.value !== "" || force === true) {
      this.addClass(CLASS_HAS_VALUE)
    } else {
      this.removeClass(CLASS_HAS_VALUE)
      this.element.value = ""
    }
  }

  /**
   * Destroys the component and frees all references.
   */
  public destroy() {
    this.element.removeEventListener("input", this._changedHandler)

    if (this.element.getAttribute("type") === "password") {
      this.element.removeEventListener("animationstart", this._animationStartHandler)
    }

    (this as any)._changedHandler = undefined;
    (this as any)._animationStartHandler = undefined

    this._destroyDatePicker()
  }

  /**
   * Displays the specified error text underneath the input field.
   * @param {text} text The error text/html to display; or undefined to hide the message.
   */
  public showError(text: string) {
    let message
    if (this.element.parentElement) {
      let msg = this.element.parentElement.querySelector(CLASS_MESSAGE)

      if (msg) {
        message = new DomElement(msg)
      }
    }

    if (!text || text === "") {
      if (message) {
        remove(message.element)
      }

      this.removeClass("invalid")
      return
    }

    this.addClass("invalid")

    if (!message) {
      message = new DomElement("div")
        .addClass("message")

      this.element.parentElement!.appendChild(message.element)
    } else {
      message.empty()
    }

    const icon = new DomElement("i")
      .addClass("icon")
      .addClass("icon-026-exclamation-mark-circle")
      .setAttribute("aria-hidden", "true")

    const msg = new DomElement("span")
      .setHtml(text)

    message.appendChild(icon)
    message.appendChild(msg)
  }
}

export function init() {
  searchAndInitialize<HTMLInputElement>(".input-field input", (e) => {
    new InputField(e)
  }, (e) => e.parentElement!)
}

export default InputField
